import React from "react";
import Home from "./views/Home/Home";
import Menu from "./Components/Menu/Menu";

function App() {
  return (
    <div>
      <Menu />
      <Home />
    </div>
  );
}

export default App;
