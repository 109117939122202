import React, { Component } from "react";
import "./Menu.scss";
import i18next from "../../i18n";
import SmoothScrolling from "../../utils/SmoothScrolling";
import { Fade } from "react-awesome-reveal";

export default class Menu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenSidebar: false,
      currentLang: i18next.language,
      currentNav: "begin"
    };
  }

  componentDidMount() {
    if (i18next.language === "ch") {
      document.body.classList.add("chinese");
    } else {
      document.body.classList.remove("chinese");
    }
    window.addEventListener("scroll", () => {
      const y = window.scrollY;
      if (y > 150) {
        document.getElementById("menu").classList.add("menu-on-scroll");
      } else {
        document.getElementById("menu").classList.remove("menu-on-scroll");
      }
      var removeActiveClass = function(elements) {
        for (var i = 0; i < elements.length; ++i) {
          elements[i].classList.remove("active");
        }
      };
      var anchors = document.querySelectorAll(".menu-list li");
      var previousRefElement = null;
      for (var i = 0; i < anchors.length; ++i) {
        // Get the current element by the id from the anchor's href.
        var currentRefElement = document.getElementById(
          anchors[i].getAttribute("element-scroll").substring(1)
        );
        var currentRefElementTop = currentRefElement.getBoundingClientRect()
          .top;
        // Searching for the element whose top haven't left the top of the browser.
        if (currentRefElementTop <= 0) {
          //The browser's top line haven't reached the current element, so the previous element is the one we currently look at.
          previousRefElement = anchors[i];
          // Edge case for last element.
          if (i === anchors.length - 1) {
            removeActiveClass(anchors);
            anchors[i].classList.add("active");
          }
        } else {
          removeActiveClass(anchors);
          previousRefElement.classList.add("active");
          break;
        }
      }
    });
  }

  changeLang(lang) {
    i18next.changeLanguage(lang, (err, t) => {
      if (err) return console.log("something went wrong loading", err);
      this.setState({ currentLang: lang });
      if (lang === "ch") {
        document.body.classList.add("chinese");
      } else {
        document.body.classList.remove("chinese");
      }
    });
  }

  toggleSidebar() {
    this.setState({ isOpenSidebar: !this.state.isOpenSidebar });
  }

  navigate(where) {
    SmoothScrolling.scrollTo(where);
    this.toggleSidebar();
    this.setState({ currentNav: where });
  }

  render() {
    const { isOpenSidebar, currentLang, currentNav } = this.state;

    return (
      <div>
        <div id="menu" onClick={() => this.toggleSidebar()}>
          <i className="las la-bars"></i>
        </div>
        <div
          id="side-bar"
          className={`pl-3 ${isOpenSidebar ? "open-menu" : null}`}
        >
          <Fade direction="right" duration={1200}>
            <div className="close-menu" onClick={() => this.toggleSidebar()}>
              <i className="las la-times p-2"></i>
            </div>
            <div>
              <p className="orange p-3">Menu</p>
            </div>
            <div className="menu-list p-3">
              <ul>
                <li
                  className={currentNav === "begin" ? "active" : null}
                  element-scroll="#begin"
                  onClick={() => this.navigate("begin")}
                >
                  {i18next.t("menu.home")}
                </li>
                <li
                  className={currentNav === "about" ? "active" : null}
                  element-scroll="#about"
                  onClick={() => this.navigate("about")}
                >
                  {i18next.t("menu.about")}
                </li>
                <li
                  className={currentNav === "services" ? "active" : null}
                  element-scroll="#services"
                  onClick={() => this.navigate("services")}
                >
                  {i18next.t("menu.services")}
                </li>
                <li
                  className={currentNav === "contacts" ? "active" : null}
                  element-scroll="#contacts"
                  onClick={() => this.navigate("contacts")}
                >
                  {i18next.t("menu.contacts")}
                </li>
              </ul>
            </div>

            <div className="menu-lang p-3 pt-3">
              <ul className="list-inline">
                <li
                  className={`list-inline-item ${
                    currentLang === "it-IT" ? "active" : null
                  } `}
                  onClick={() => this.changeLang("it-IT")}
                >
                  IT
                </li>
                <li
                  className={`list-inline-item ${
                    currentLang === "en-US" ? "active" : null
                  } `}
                  onClick={() => this.changeLang("en-US")}
                >
                  EN
                </li>
                <li
                  className={`list-inline-item ${
                    currentLang === "fr-FR" ? "active" : null
                  } `}
                  onClick={() => this.changeLang("fr-FR")}
                >
                  FR
                </li>
                <li
                  className={`list-inline-item ${
                    currentLang === "ch-CH" ? "active" : null
                  } `}
                  onClick={() => this.changeLang("ch-CH")}
                >
                  CN
                </li>
              </ul>
            </div>

            <div className="menu-social p-3 pt-3">
              <ul className="list-inline">
                <li className="list-inline-item">
                  <i className="lab la-facebook-f"></i>
                </li>
                <li className="list-inline-item">
                  <i className="lab la-twitter"></i>
                </li>
                <li className="list-inline-item">
                  <i className="lab la-linkedin-in"></i>
                </li>
              </ul>
            </div>
          </Fade>
        </div>
      </div>
    );
  }
}
