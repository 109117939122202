import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import itLang from "./locales/default.json";
import enLang from "./locales/default.en.json";
import frLang from "./locales/default.fr.json";
import chLang from "./locales/default.ch.json";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng:'it-IT',
    resources: {
      "it-IT": itLang,
      'en-US': enLang,
      'fr-FR': frLang,
      'ch-CH': chLang
    },
    fallbackLng: ["it-IT", "en-US", "fr-FR", "ch-CH"],
    debug: true,
    ns: ["common"],
    defaultNS: "common",
    interpolation: {
      escapeValue: true // not needed for react as it escapes by default
    },
    react: {
      wait: true,
      bindI18n: "languageChanged loaded",
      bindStore: "added removed",
      nsMode: "default"
    }
  });

export default i18n;
