import "./Home.scss";
import { NavLink } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import React, { Suspense, useEffect } from "react";
import { useTranslation } from "react-i18next";
import SmoothScrolling from "../../utils/SmoothScrolling";
import { Fade } from "react-awesome-reveal";
import $ from 'jquery';

function Home() {
  const scroll = where => {
    SmoothScrolling.scrollTo(where);
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      const y = window.scrollY;
      if (y > window.innerHeight / 2) {
        document.getElementById("go-up").style.opacity = 1;
      } else {
        document.getElementById("go-up").style.opacity = 0;
      }
    });

    var validations = {
      required: function (value) {
        return value !== "";
      },
      phone: function (value) {
        return value.match(
          /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
        );
      },
      email: function (value) {
        return value.match(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
      }
    };

    var form = document.getElementById("contactForm"),
      inputsArr = form.querySelectorAll("input, textarea"),
      errorMessage = document.querySelector(".ui.error.message"),
      successMessage = document.querySelector(".ui.success.message");

    form.addEventListener(
      "submit",
      function (e) {
        var i = 0;
        while (i < inputsArr.length) {
          var attr = inputsArr[i].getAttribute("data-validation"),
            rules = attr ? attr.split(" ") : "",
            parent = inputsArr[i].closest(".form-field"),
            j = 0;
          while (j < rules.length) {
            if (!validations[rules[j]](inputsArr[i].value)) {
              e.preventDefault();
              errorMessage = inputsArr[i].parentElement.getElementsByClassName('input-error')[0].classList.add('show');
              return false;
            }
            else {
              errorMessage = inputsArr[i].parentElement.getElementsByClassName('input-error')[0].classList.remove('show');
            }
            j++;
          }
          i++;
        }
        e.preventDefault();
        successMessage.className = "ui success message";
        $.ajax({

          type: "POST",
          url: "inc/sendEmail.php",
          data: $('form').serialize(),
          success: function (msg) {

            // Message was sent
            if (msg == 'OK') {
              $('.message-warning').fadeOut();
              $('#contactForm').fadeOut();
              $('.message-success').fadeIn();

              form.reset();
            }
            // There was an error
            else {
              $('.message-warning').html(msg);
              $('.message-warning').slideDown("slow");
            }

          },
          error: function () {

            $('.message-warning').html("Something went wrong. Please try again.");
            $('.message-warning').slideDown("slow");

          }

        });
      },
      false
    );
  });
  const { t, i18n } = useTranslation();
  return (
    <div id="begin">
      <div className="home d-flex p-4 align-items-center justify-content-center">
        <div className="logo">
          <NavLink to="#home" className="logo zindex-2">
            <img src={logo} alt="logo" />
          </NavLink>
        </div>
        <div className="zindex-2 home-content">
          <Fade triggerOnce direction="top" duration={1500}>
            <h3 className="hello">{t("hello")}</h3>
            <h1
              className="description"
              dangerouslySetInnerHTML={{ __html: t("description") }}
            ></h1>
            <div className="buttons zindex-2">
              <button className="home-button mr-3 mt-5" value="" onClick={() => scroll("contacts")}>
                {t("idea")}
              </button>
              <button className="home-button mt-2" value="" onClick={() => scroll("about")}>
                {t("about")}
              </button>
            </div>
          </Fade>
        </div>
        <div className="barretta-arancione d-none d-md-inline"></div>
        <div className="scroll-button d-none d-md-inline" onClick={() => scroll("about")}>
          <i className="las la-angle-down mr-2"></i>
          SCROLL
        </div>
        <div>
          <ul className="home-social">
            <li>
              <a href="https://www.facebook.com/NTCompanySrl" target="_blank">
                <i className="fab fa-facebook-f"></i>
                <span>Facebook</span>
              </a>
            </li>
            <li>
              <a href="https://twitter.com/company_nt" target="_blank">
                <i className="fab fa-twitter"></i>
                <span>Twiiter</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/nt-companysrl/about/"
                target="_blank"
              >
                <i className="fab fa-linkedin-in"></i>
                <span>LinkedIn</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div id="about" className="text-center">
        <div className="barretta-bianca d-none d-md-inline"></div>
        <div className="container ">
          <Fade triggerOnce direction="top" duration={1500}>
            <h3>{t("abouts.hello")}</h3>
            <h1>{t("abouts.title")}</h1>
            <div className="separator"></div>
          </Fade>
          <Fade triggerOnce direction="top" duration={1500}>
            <div
              dangerouslySetInnerHTML={{ __html: t("abouts.description") }}
            ></div>
          </Fade>
        </div>
      </div>
      <div className="go-up" id="go-up" onClick={() => scroll("begin")}>
        <i className="las la-arrow-up"></i>
      </div>
      <div id="services" className="text-center">
        <div className="container ">
          <Fade triggerOnce direction="top" duration={1500}>
            <h3>{t("services.title")}</h3>
            <h1>{t("services.description")}</h1>
            <div className="separator"></div>
          </Fade>
          <div className="row">
            {t("services.list", { returnObjects: true }).map(
              ({ icon, title, description }, index) => (
                <div className="col-md-6 service-item mt-5" key={index}>
                  <Fade
                    triggerOnce
                    direction={index % 2 === 0 ? "left" : "right"}
                    duration={1500}
                  >
                    <div className="d-flex flex-column flex-md-row">
                      <i className={icon}></i>
                      <div className="text-center text-md-left">
                        <h3 className="h2">{title}</h3>
                        <p
                          dangerouslySetInnerHTML={{ __html: description }}
                        ></p>
                      </div>
                    </div>
                  </Fade>
                </div>
              )
            )}
          </div>
          <div className="barretta-arancione"></div>
        </div>
      </div>
      <div id="contacts">
        <div className="overlay"></div>
        <div className="barretta-bianca"></div>
        <div className="text-center">
          <div className="container">
            <Fade triggerOnce direction="top" duration={1500}>
              <h3>{t("contacts.title")}</h3>
              <h1>{t("contacts.description")}</h1>
              <div className="separator"></div>
            </Fade>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-8 form-container">
                <div className="title">{t("contacts.form.title")}</div>
                <form
                  name="contactForm"
                  id="contactForm"
                  method="post"
                  action=""
                  noValidate="noValidate"
                  className="mt-5"
                >
                  <fieldset>
                    <div className="ui error message hidden"></div>
                    <div className="ui success message hidden"></div>
                    <div className="form-field form-group">
                      <input
                        name="contactName"
                        type="text"
                        id="contactName"
                        placeholder={t("contacts.form.name")}
                        minLength="2"
                        data-validation="required"
                        aria-required="true"
                        className="full-width form-control mb-4"
                        onChange={() => { }}
                      />
                      <span id="input-error" className="input-error">{t("contacts.error", {
                        returnObjects: true
                      })[0]}</span>
                    </div>
                    <div className="form-field form-group">
                      <input
                        name="contactEmail"
                        type="email"
                        id="contactEmail"
                        placeholder={t("contacts.form.email")}
                        minLength="2"
                        data-validation="required email"
                        aria-required="true"
                        className="full-width form-control mb-4"
                        onChange={() => { }}
                      />
                      <span id="input-error" className="input-error">{t("contacts.error", {
                        returnObjects: true
                      })[1]}</span>
                    </div>
                    <div className="form-field form-group">
                      <input
                        name="contactSubject"
                        type="text"
                        id="contactSubject"
                        placeholder={t("contacts.form.subject")}
                        minLength="2"
                        data-validation="required"
                        aria-required="true"
                        className="full-width form-control mb-4"
                        onChange={() => { }}
                      />
                      <span id="input-error" className="input-error">{t("contacts.error", {
                        returnObjects: true
                      })[2]}</span>
                    </div>
                    <div className="form-field form-group">
                      <textarea
                        name="contactMessage"
                        id="contactMessage"
                        placeholder={t("contacts.form.message")}
                        rows="10"
                        cols="50"
                        data-validation="required"
                        aria-required="true"
                        className="full-width form-control mb-2"
                      ></textarea>
                      <span id="input-error" className="input-error">{t("contacts.error", {
                        returnObjects: true
                      })[3]}</span>
                    </div>
                    <div className="form-field mt-5">
                      <button className="btn btn-primary btn-block">
                        {t("contacts.form.send")}
                      </button>
                    </div>
                  </fieldset>
                </form>
                <div className="message-success">
                  Il tuo messaggio è stato inviato, grazie!<br />
                </div>
                <div className="message-warning">
                  Servizio non disponibile, riprova dopo.
                </div>
              </div>
              <div className="col-md-4 contact-container">
                <div className="title">{t("contacts.info.title")}</div>
                <div className="info">
                  <h6>{t("contacts.info.description")}</h6>
                  {t("contacts.info.places", { returnObjects: true }).map(
                    (place, index) => (
                      <span
                        dangerouslySetInnerHTML={{ __html: place }}
                        key={index}
                      ></span>
                    )
                  )}
                  <h6>{t("contacts.form.email")}</h6>
                  <p className="mt-4">info@ntcompany.it</p>
                  <h6>{t("contacts.info.tel")}</h6>
                  <p className="mt-4">
                    {t("contacts.info.telFax")} : 0396322669
                  </p>
                </div>
                <div className="social-icon text-left pt-3">
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <i className="lab la-facebook-f"></i>
                    </li>
                    <li className="list-inline-item">
                      <i className="lab la-twitter"></i>
                    </li>
                    <li className="list-inline-item">
                      <i className="lab la-linkedin-in"></i>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="copyright container">
          <span>© Copyright NT Company 2019</span>
          <span>
            Site by{" "}
            <a href="mailto:zappiagiuseppe@gmail.com">Zappia Giuseppe</a>
          </span>
        </div>
      </div>
    </div>
  );
}

export default function App() {
  return (
    <Suspense fallback="loading">
      <Home />
    </Suspense>
  );
}
